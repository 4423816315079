.image-component {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 12px;
}

.gallery-container {
  padding: 12px;
  margin: 12px 0;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  max-width: 100%;
}

.gallery-header {
  font-size: 20px;
  text-align: center;
  margin: 4rem 0;
  width: 100%;
  padding-top: 3rem;
}

@media (max-width: 767px) {
  .gallery-header {
    font-size: 18px;
    margin: 2rem 0;
  }
}

@media (min-width: 767px) {
  .gallery-header {
    font-size: 20px;
    margin: 4rem 0;
  }
}
