@font-face {
  font-family: 'OldSansBlack';
  src: url('../public/OldSansBlack.ttf') format('truetype');
}

body,
html {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(203, 203, 202, 183);
  text-transform: uppercase;
  min-height: 100vh;
}

body,
a {
  font-family: 'OldSansBlack', sans-serif !important;
}

/* CSS for the layout */
.app-container {
  display: grid;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex-grow: 1;
  padding-left: 20px; /* Add spacing as needed */
  padding-right: 20px; /* Add spacing as needed */
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
