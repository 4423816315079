/* Home.css */

/* Container styles */
.home-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

/* Image styles */
.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .centered-image {
    max-width: 80%; /* Adjust the size as needed */
    max-height: 80%; /* Adjust the size as needed */
  }
}
