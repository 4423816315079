/* Common styles for navbar and logo */
.navbar,
.logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Logo styles */
.logo-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  padding: 1rem;
  text-align: center;
}

/* Sidebar icon and menu styles */
.sidebar-icon {
  position: fixed;
  padding: 20px;
  left: 17px;
  background-color: transparent;
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: black;
  z-index: 3;
}

.sidebar-menu {
  position: fixed;
  top: 0px;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.1s ease;
  z-index: 2;
}

/* Styles for the open sidebar menu */
.sidebar-menu.open {
  transition: background-color 0.5s ease !important;
  background-color: rgba(39, 66, 0, 0.232);
  backdrop-filter: blur(18px);
  bottom: 0;
  top: 0;
  max-height: 100%;
}

.sidebar-menu ul {
  list-style: none;
  padding: 0;
}

.sidebar-menu ul li a {
  margin-top: 1rem;
  color: #ffffff;
  text-decoration: none;
  display: block;
}

@media (min-width: 768px) {
  .logo-img {
    max-width: 30%;
  }
  .sidebar-icon {
    font-size: 45px;
  }
  .sidebar-menu ul li {
    padding: 1.1vw;
    font-size: 5.5vw;
  }
}

@media (max-width: 767px) {
  .logo-img {
    max-width: 50%;
  }
  .sidebar-icon {
    font-size: 25px;
  }
  .sidebar-menu ul li {
    padding: 5vw;
    font-size: 15vw;
  }
}

/* Navbar styles */
.navbar {
  z-index: 3;
}

/* Original style for the first list item */
.sidebar-menu ul li:first-child {
  padding-top: 60px;
}

/* Equivalent style using margin on the first list item */
.sidebar-menu ul li:first-child {
  margin-top: 60px;
}

.background-container {
  height: 0;
}

/* Different colors for each list item on hover */
.sidebar-menu ul li a:hover {
  color: rgb(0, 0, 0);
}
