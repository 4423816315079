.footer-container {
  text-align: center;
  color: black;
  margin-top: auto;
}

.icon-container a {
  min-width: 30px;
  min-height: 30px;
}

.icon {
  font-size: 1.2rem;
  color: black;
}
