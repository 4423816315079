.contact-background {
  position: center;
  top: 50px; /* Adjust the top to leave space for the navbar */
  bottom: 0; /* Fill the space below the navbar and footer */
  left: 0;
  right: 0;
  background-size: cover;
}

.contact-container {
  display: grid;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

/* In your Contact.css file */
.contact-text-container {
  display: grid;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.contact-text {
  text-align: center;
}

.contact-image {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (max-width: 767px) {
  .contact-text-container {
    font-size: 18px;
    margin: 3rem 0;
  }
}

@media (min-width: 767px) {
  .contact-text-container {
    font-size: 66px;
  }
}
